<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">What about you?</h1>
        <h2 class="ca-subtitle">Would you consider yourself to be...</h2>
      </v-col>
    </v-row>
    <v-row class="ca-content">
      <v-col class="ca-options ca-overalls">
      <v-row>
        <v-col cols="12" sm="4" v-for="(card, i) in cards" :key="i">
          <overall-card
            :option="card.option"
            :gif="card.gif"
            :jpg="card.jpg"
            :copy="card.copy"
          >
          </overall-card>
        </v-col>
      </v-row>
    </v-col>
    </v-row>
    
  </div>
</template>

<script>
import OverallCard from "../components/OverallCard.vue";
export default {
  data() {
    return {
      cards: [
        {
          option: "classic",
          gif: require("../assets/images/overall/classic.gif"),
          jpg: require("../assets/images/overall/classic.jpg"),
          copy: "Why reinvent the wheel? Inventory, deals, and location are important to you and your customers and they deserve the spotlight.",
        },
        {
          option: "storyteller",
          gif: require("../assets/images/overall/storyteller.gif"),
          jpg: require("../assets/images/overall/Storyteller.jpg"),
          copy: "You have a story to tell and a picture is worth a thousand words! What better way to tell your story than through your advertising!",
        },
        {
          option: "trendsetter",
          gif: require("../assets/images/overall/trendsetter.gif"),
          jpg: require("../assets/images/overall/trendsetter.jpg"),
          copy: "You’re willing to try something new and do what your competitors aren’t to push the envelope and make an impression!",
        },
      ],
    };
  },
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    OverallCard,
  },
};
</script>
