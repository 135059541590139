<template>
  <div
    class="ca-overall-card ca-option text-center"
    :class="{ chosen: chosen }"
    @click="updateChoice(option, 'overall')"
  >
    <img :src="changePicture">
    <h2 class="ca-card-title mt-2 mb-2 text-center text-capitalize">
      {{ option }}
    </h2>
    <p>{{ copy }}</p>
  </div>
</template>

<script>
import updateChoice from "../mixins/mixin";
import { mapGetters } from "vuex";

export default {
  props: {
    option: String,
    gif: String,
    jpg: String,
    copy: String,
  },
  mixins: [updateChoice],
  computed: {
    ...mapGetters(["getChoice"]),
    changePicture() {
      return this.chosen ? this.gif : this.jpg;
    },
    chosen() {
      return this.getChoice("overall") === this.option;
    },
  },
};
</script>

<style scoped>
.ca-overall-card img {
  margin: auto;
  max-width: 100%;
  max-height: 360px;
}

.ca-overall-card {
  max-width: 400px;
  margin: auto;
  height: 100%;
}

@media (max-width: 599px) {
  .ca-overall-card img {
    max-height: 300px;
  }
}
</style>
